/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 16:45:48
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-07-21 11:09:55
 * @Description: 员工实体
 */
import { useOptionStore } from '@/stores/modules/option'

export interface IEmployee {
  id?: string
  name?: string
  enterpriseRole?: string
  documentPhoto?: string
  identityCardNum?: string
  enterpriseId?: string
  score?: string | number
  reject?: string
  auditStatus?: string
  reviewBy?: string
  reviewAt?: string
  deadlineAt?: string
}

export class Employee implements IEmployee {
  id?: string
  name?: string
  enterpriseRole?: string
  documentPhoto?: string
  identityCardNum?: string
  enterpriseId?: string
  score?: string | number
  reject?: string
  auditStatus?: string
  reviewBy?: string
  reviewAt?: string
  deadlineAt?: string

  constructor(json: IEmployee = {}) {
    this.id = json.id || ''
    this.name = json.name || ''
    this.enterpriseRole = json.enterpriseRole || ''
    this.documentPhoto = json.documentPhoto || ''
    this.identityCardNum = json.identityCardNum || ''
    this.enterpriseId = json.enterpriseId || ''
    this.reject = json.reject || ''
    this.score = json.score
    this.auditStatus = json.auditStatus || ''
    this.reviewBy = json.reviewBy || ''
    this.reviewAt = json.reviewAt
    this.deadlineAt = json.deadlineAt
  }

  get auditStatusText() {
    const optionStore = useOptionStore()
    return optionStore.getStatusName(this.auditStatus || '')
  }
}
